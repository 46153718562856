/* CenteredPage.css */

.centered-page {
    position: fixed;
    top: 2%;
    left: 50%;
    transform: translate(-50%, -2%);
    height: 100%;

  }

  @media only screen and (max-width: 600px) {
    .centered-page {
      width: 90%;
      max-width: 400px;
    }
  }