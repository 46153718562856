.container {
    position: relative;
    width: 100%;
    height: 100px;
    
  }
  
  .moving-div {
    position: absolute;
    width: 400px;
    height: 100px;
    animation: moveRightToLeft 5s linear infinite; /* Animation name, duration, timing function, and iteration count */
  }
  
  @keyframes moveRightToLeft {
    from {
      transform: translateX(0%);
  }
  to {
      transform: translateX(-100%);
  }
  }
  