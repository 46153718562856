.price-item{
    list-Style:none;
    padding-Left:20px;
    max-width: 300px;
}

.price-item-column1{
    width:65%;
    display:inline-block
}
.price-item-column2{
    width:25%;
    display:inline-block
}