
.container {
    position: relative;
    height: 100vh;
  }
  
  .fixed-top {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff; 
    padding: 10px; 
  }
  
  .scrollable-content {
    position: relative;

    bottom: 50;
    width: 80%;
    overflow-y: auto;
    
    padding-top: 10px;    
    padding-right: 20px;  
    padding-bottom: 75px;  
    padding-left: 20px;  
  }
  
  .fixed-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff; 
    padding: 10px; 
  }
  